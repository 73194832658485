import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  rightImage,
  imageRound1,
  imageRound2,
  imageRound3,
  imageRound4
} from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  dataList: any;
  items: any;
  cardList: any;
  displayLimit: number;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      cardList: [
        {
          image: imageRound1,
          name:
            "Cloud-native App and service store platform for automated network assurance for infinite connected services.",
          description:
            "ENGEEM provides scalable analytics, operational flow, and managed services that give operators and connected industries the ability to easily move to data monetisation, securely create new services, and new revenue streams – only leveraging their existing connectivity resources"
        },
        {
          image: imageRound2,
          name:
            "End-to-end programmable network analytics with high impact on enriching device and end-user experience, TCO efficiency, network resource experience and revenue streams creation.",
          description:
            "ENGEEM strictly focuses on providing the most insightful of the end-user experience of mobile connection."
        },
        {
          image: imageRound3,
          name:
            "Easily develop a end-to-end monitoring applications that remove network complexity barriers",
          description:
            "Security, User Experience, Network Resources, Automation, and other Analytics, we provide the full path to digital service operation and innovation, enabling industry to reduce technology skills barriers, meeting service experience and business innovation requirement."
        },
        {
          image: imageRound4,
          name: "Instant network fault control and monitoring.",
          description:
            "Why use sophisticated tools or costly human intervention to understand anomalies and root causes when you can instantly identify what your network instabilities are, where they are located, and which customer segments they affect?"
        }
      ],
      items: [
        {
          image: rightImage,
          description:
            "KPI do not work and Dashboards are worthless ...  Static analytics rarely tell you what and where your network problems are, Who is affected, and how much does it cost for your business!"
        },
        {
          image: rightImage,
          description:
            "Management Functions design and interconnection: performance, supervision, fault, ..."
        },
        {
          image: rightImage,
          description:
            "Automated O&M: Self-Service, Self-Assurance, Orchestration"
        },
        {
          image: rightImage,
          description:
            "Experience Assurance: User Experience, Expert-system engineering,Closed-loop pipeline."
        },
        {
          image: rightImage,
          description:
            "Resource Management and Traffic engineering: congestion mitigation, rerouting, and effortless experience-based operation"
        },
        {
          image: rightImage,
          description:
            "Automation Workflows & Process : Programmable process and rules development to decouple management and network systems."
        }
      ],
      dataList: [
        {
          name: "Shorten Troubleshooting pipe",
          description:
            "Eliminating costly process and tools in identifying network problems"
        },
        {
          name: "Heavy Manual Analysis",
          description: "Sync QoS with pre-built User Experience connectors"
        },
        {
          name: "Break Siloed Monitoring",
          description: "Design and run multi-domain network monitoring system"
        },
        {
          name: "Fix bad network development and management strategy",
          description:
            "Efficiently drive Network Technology studies and operation integration strategy to save 1M$ per year by reverting risks"
        },
        {
          name: "Significant skills scarcity",
          description:
            "Enforce and meet teams with network Expertise to ensure project success."
        },
        {
          name: "Quality of Experience Development Reliability",
          description:
            "Providing vital pre-integrated analytics for user experience satisfaction development and QoS reliability"
        },
        {
          name: "Cost Reduction in the Telecom Industry",
          description:
            "Smartly design management architecture for data-driven modern monitoring"
        },
        {
          name: "Processing network data is expensive",
          description:
            "Removing massive hardware and complex architecture requirements for user plan data monitoring plans"
        },
        {
          name: "Flat Analytics and Low-value KPIs",
          description:
            "Developing operational analytics for perspective operation improvement system than useless dashboard approach"
        },
        {
          name: "5G Network Opportunities",
          description:
            "Integrating strong and creative digital capabilities to boost vertical offering "
        },
        {
          name: "Time Consuming solution integration and evolution",
          description:
            "AI-Based ENGEEM integration service for streamlining NetOps processes"
        }
      ],

      displayLimit: 5
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleloginRedirect = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock");
  };
  handleSignUpRedirect = () => {
    this.props.navigation.navigate("SignUpPage", {
      orgTab: "false"
    });
  };
  handleViewMoreClick = () => {
    // Toggle the showMore state on button click
    this.setState(prevState => ({ displayLimit: prevState.displayLimit + 5 }));
  };
  // Customizable Area End
}
