import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import { image_logo, Shape, Twitter, youtube, Google, Facebook } from "./assets";
import { createTheme } from "@material-ui/core/styles";

// Customizable Area End

import EmailAccountLoginController, {
    Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End


export default class EmailAccountLoginBlock extends EmailAccountLoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            // Required for all blocks
            <>
                <Box style={styles.mainBox0}>
                    <Box>
                        <Box style={styles.imageRoot}>
                            <Box>
                                <img style={{ width: "100%" }} src={image_logo}></img>
                            </Box>
                            <Box>
                                <Typography style={{ textAlign: "center", color: "white", fontWeight: 700 }}>Lorem ipsum dolor sit amet consectetur.
                                    Semper imperdiet tellus mi blandit
                                </Typography>
                                <Typography style={{ textAlign: "center", color: "white", fontWeight: 700 }}>tortor.
                                    At maecenas faucibus vivamus vitae mauris eget metus eget ridiculus.
                                </Typography>
                            </Box>
                        </Box>
                        <Box style={styles.imageshocialMedia}>
                            <img style={{ height: "25px", marginTop: "5px" }} src={Shape}></img>
                            <img style={{ marginLeft: "7px", height: "25px", marginTop: "5px", width: "30px" }} src={youtube}></img>
                            <img src={Google}></img>
                            <img src={Facebook}></img>
                            <img src={Twitter}></img>
                        </Box>
                    </Box>
                </Box>
                <Box />
            </>
            // Customizable Area End
        );
    }

}
// Customizable Area Start
const styles = ({
    imageshocialMedia: { display: "flex", justifyContent: "center", gap: "20px", marginBottom: '20px' },
    imageRoot: { height: 'calc(100vh - 130px)' },
    mainBox0: { height: "100%", width: "50vw", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "0 50px 50px 0", background: "linear-gradient(153.11deg, #232638 8.67%, #1391AB 100%)" },
})
// Customizable Area End
