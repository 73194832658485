import React from "react";


// Customizable Area Start
import {
    Box,
    Typography,
    Button,
    Grid,
    Divider,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Shape, Twitter, youtube, Google, Facebook } from "../../email-account-login/src/assets";
import { Frame12, Frame68, Frame47, Frame62, Frame78, Frame11, Frame22, Frame66, image_logo, card, computer, service, image07, imageRound1, imageRound2, imageRound3, imageRound4, imageRound5, imageRound6, Image_logo1, image01, image02, image03 } from "./assets";
import { createTheme } from "@material-ui/core/styles";

// Customizable Area End

import LandingPageController, {
    Props,
} from "./LandingPageController";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End


export default class LandingPageWeb extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        
        return (
            // Customizable Area Start
            // Required for all blocks
            <>
                <Box style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width:"80%", margin:"auto"}}>
                    <Box>
                        <Box style={styles.mainBox}>
                            <Box style={{ marginLeft: "5%", width: "auto" }}>
                                <Box style={{ color: "or", fontSize: "36px" , width: "auto"}}>
                                    <img style={{ width: "100%", marginLeft: "-66px" , paddingTop: "3%", display: "flex"}} src={Image_logo1}></img>
                                </Box>


                            </Box>
                            <Box style={{ display: "flex", gap: "10%"}}>
                                <Box>
                                    <Button onClick={() => this.handleloginRedirect()} data-testId="loginpage" style={{ color: "rgb(19, 145, 171)", borderRadius: "8%", border: "2px solid rgb(19, 145, 171)", width: "7vw", textTransform: "inherit", marginRight:"-100px", letterSpacing:"0.01857em", fontSize:"1.175rem"}}>
                                        Log in
                                    </Button>
                                </Box>
                                <Box>
                                    <Button data-testId="signupPage" onClick={() => this.handleSignUpRedirect()} style={{ background: "rgba(19, 145, 171, 1)", color: "white", borderRadius: "8%", border: "2px solid rgb(19, 145, 171)", width: "7vw", textTransform: "inherit", marginLeft:"100px" ,letterSpacing:"-0.01em", fontSize:"1.175rem"}}>
                                        Sign  up
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box style={styles.rootbox1}>
                    <Box style={{ padding: "5%" }}>
                        <Typography style={{ fontWeight: 400, lineHeight: "28px", fontSize: "24px", textTransform: "uppercase", fontFamily: "Roboto",letterSpacing:"0.02938em" }}>Attention IP and Network Operators who want a modern network management system</Typography>
                        <Typography style={styles.rootbox3}>How can operators run and orchestrate autonomous network management functionalities across multi-domains and operation systems Without fragmented deployment architecture,inconsistent data management, and costly manpower in less than 10 days Guaranteed!</Typography>
                        <Box style={{ marginTop: "2%" }}>
                            <Typography style={styles.rootbox4}>Claim Your FREE 1-Month Trial to Discover the Step-By-Step Blueprint To Easily Design long-term technology architecture and management automation functions (Valued at €65 000)</Typography>
                        </Box>
                        <Box style={{ color: "white", height: "25px" }}>/</Box>
                        <Box style={{ display: "flex", marginTop: "55px", marginBottom: "5%" }}>
                            <Box style={{ display: "flex" }}>
                                <Button data-testId="FreeTrialButton" onClick={() => this.handleSignUpRedirect()} style={{ backgroundColor: "#1391AB", textTransform: "inherit", color: "white", height: "45px", width: "30vw", fontWeight: 700 }} >Claim Your FREE TRIAL</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box style={{ color: "white", height: "25px" }}>/</Box>
                <Box style={styles.rootbox5}>
                    <Box style={{ padding: "5%" }}>
                        <Typography style={{ fontWeight: 700, lineHeight: "48px", fontSize: "32px", textAlign: "center", fontFamily: "Roboto" }}>Finally ... A Quick and Easy way to End costly and time-consuming network operating improvement plans!</Typography >
                        <Typography style={styles.rootbox7}>Whatever the golden vendor, products, and management procedures that have been invested for so long, NetOps teams are rarely aware of what is truly happening to network end-users. Are you sick of that? Tired of product outcomes inefficiency, operational drag on your teams, and costing you money every year? And looking for ways to proactively identify End-to-End network faults, critical service experience impact, and fix them faster?</Typography>
                        <Typography style={styles.rootbox8}>At ENGEEM we help IT and Network Operators achieve a Perfect Monitoring and Management system in the shortest time possible using industry leading innovations.</Typography>
                        <Typography style={styles.rootbox9}>We did it without years of hard work and trials and errors!</Typography>
                    </Box>
                </Box>
                <Box style={{ color: "white", height: "25px" }}>/</Box>
                <Box style={styles.rootbox10}>
                    <Box style={styles.rootbox11} className="rootbox12">
                        <Typography style={{ textAlign: "center", fontWeight: 700, lineHeight: "48px", fontSize: "32px", marginLeft: "3%", fontFamily: "Roboto" }}>
                            Here’s A Brief Outline Of What We’ll Cover during Your FREE 1-Month Trial
                        </Typography>
                        {
                            this.state.items.map((item: any) => {
                                return (
                                   <Box style={{ display: "flex", marginTop: "2%" }}>
                                     <Box  style={{ height: "20%" }}>
                                          <img  src={item.image} />

                                        </Box>
                                    <Box>
                                        <p style={styles.rootbox13}>{item.description}</p>
                                    </Box>
                                    </Box>
                                )
                            })
                        }
                        <Box style = {styles.buttonroot} >
                            <Box style={{ display: "flex", marginTop:"199px" }} >
                                <Button data-testId="newbutton" onClick={() => this.handleSignUpRedirect()} style={{ backgroundColor:"#1391AB",textTransform: "inherit", color: "white", width: "30vw", fontWeight: 700,  marginLeft:"32px"}} className="root19" >Claim Your FREE TRIAL</Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box style={{ display: "flex" }}>
                        <img style={{ height: "106%", width: "37vw" }} src={image01} />
                    </Box>
                </Box>
                <Box style={styles.rootbox15}>
                    <Box>
                        <Typography style={{ textAlign: "center", fontWeight: 700, lineHeight: "54px", fontSize: "36px", fontFamily: "Roboto", marginTop:"2.5%", marginRight:"60px"}}>
                            We've produced impressive outcomes for ourselves and our clients, which
                        </Typography>
                        <Typography style={{ textAlign: "center", fontWeight: 700, lineHeight: "54px", fontSize: "36px", fontFamily: "Roboto", marginRight:"40px" }}>
                            we've pre-built for you to use now with a single click of a button
                        </Typography>
                        <Box style={styles.rootbox18}>
                        
                        <div className="App">
                        {this.state.dataList.slice(0, this.state.displayLimit).map((data:any) => (
          <div key={data.name}>
              <ul>
             <li style={{ fontWeight: 700}}>{data.name}</li>
            </ul>
            <p style={{ fontWeight: 400}} >{data.description}</p>
            </div>
            ))}

{this.state.dataList.length > this.state.displayLimit && (
          <Typography data-testId= "viewMore"  style={{ color: "#1391AB", fontWeight: 700, cursor:"pointer"}} onClick={this.handleViewMoreClick}>View more</Typography>
        )}

           
          </div>
           
         



                            
                            <Box style={{ width: "51%" }}>
                                <img style={styles.rootbox21} src={image02} />
                                <img style={styles.rootbox20} src={image03} />
                            </Box>
                        </Box>
                        <Box style={{ width: "99%" }}>
                            <Typography style={styles.rootbox22}>Over the last 25 years we’ve shown to dozens of IT and operators how to build the perfect monitoring strategy, manage technology integration efficiently for reducing total expenditure costs without years of hard work and trials and errors; And we can do it for you too!</Typography>
                        </Box>
                    </Box>
                </Box>

                <Box style={styles.rootbox23}>
                    <Box>
                        <Typography style={{ fontWeight: 700, color: "white", textAlign: "center", marginTop: "2%", fontSize:"32px", lineHeight:"48px" }}>Here’s Another Taste Of What You Can Expect</Typography>
                        <Typography style={{ fontWeight: 700, color: "white", textAlign: "center", fontSize:"32px", lineHeight:"48px" }}>When You Book Your FREE Trial Project</Typography>
                    </Box>
                    <Grid container spacing={2} style={{ marginTop: "4%" }} >
                        <Grid item xs={6}>
                            <Button style={{ display: "block", background: "white", width: "80%", height: "475px", borderRadius: "16px", marginLeft: "20%", textTransform: "inherit", textAlign: "start" }}>
                                <Box style={{ background: "#EDF9FC", padding: "9% 4% 4% 4%", borderRadius:"16px" }}>
                                    <img src={imageRound1} style={{ height: "10%", width: "10%" }} />
                                    <Typography style={{ fontWeight: 700, letterSpacing:"0.01938em", fontSize:"20px"}}>Cloud-native App and service store platform for automated network assurance for infinite connected services.</Typography>
                                    <Typography style={{ fontSize: "20px" , marginTop:"4px"}}>ENGEEM provides scalable analytics, operational flow, and managed services that give operators and connected industries the ability to easily move to data monetisation, securely create new services, and new revenue streams – only leveraging their existing connectivity resources</Typography>
                                </Box>
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button style={{ display: "block", background: "white", width: "80%", height: "475px", borderRadius: "16px", textTransform: "inherit", textAlign: "start" }}>
                                <Box style={{ background: "#EDF9FC", padding: "9% 4% 10% 4%", borderRadius:"16px"  }}>
                                    <img src={imageRound2} style={{ height: "10%", width: "10%" }} />
                                    <Typography style={{ fontWeight: 700, fontSize:"20px"}}>End-to-end programmable network analytics with high impact on enriching device and end-user experience, TCO efficiency, network resource experience and revenue streams creation.</Typography>
                                    <Typography style={{  fontSize: "20px", marginTop:"6%"}}>ENGEEM strictly focuses on providing the most insightful of the end-user experience of mobile connection.</Typography>
                                </Box>
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button style={{ display: "block", background: "white", width: "80%", height: "475px", borderRadius: "16px", marginLeft: "20%", textTransform: "inherit", textAlign: "start" }}>
                                <Box style={{ background: "#EDF9FC", padding: "9% 4% 8% 4%", borderRadius:"16px"  }}>
                                    <img src={imageRound3} style={{ height: "10%", width: "10%", marginTop:"5px" }} />
                                    <Typography style={{ fontWeight: 700 , fontSize:"20px"}}>Easily develop a end-to-end monitoring applications that remove network complexity barriers</Typography>
                                    <Typography style={{fontSize: "18px", marginTop:"4px" }}>Security, User Experience, Network Resources, Automation, and other Analytics, we provide the full path to digital service operation and innovation, enabling industry to reduce technology skills barriers, meeting service experience and business innovation requirement.</Typography>
                                </Box>
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button style={{ display: "block", background: "white", width: "80%", height: "475px", borderRadius: "16px", textTransform: "inherit", textAlign: "start" }}>
                                <Box style={{ background: "#EDF9FC", padding: "9% 4% 13% 4%", borderRadius:"16px"  }}>
                                    <img src={imageRound4} style={{ height: "10%", width: "10%" }} />
                                    <Typography style={{ fontWeight: 700, fontSize:"20px"}}>Instant network fault control and monitoring.</Typography>
                                    <Typography style={{ fontSize: "18px", marginTop:"7%"}}>Why use sophisticated tools or costly human intervention to understand anomalies and root causes when you can instantly identify what your network instabilities are, where they are located, and which customer segments they affect?</Typography>
                                </Box>
                            </Button>
                        </Grid>
                        <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                            <Button style={{ display: "block", background: "white", width: "80%", height: "300px", borderRadius: "16px", textTransform: "inherit", textAlign: "start" }}>
            <div style={{height:"275px", width:"100%",background: "#EDF9FC", padding: "9% 4% 3% 4%", borderRadius:"16px" }}>
          
                                    <img src={imageRound5} style={{ height: "24%", width: "5%" }} />
                                    <Typography style={{ fontWeight: 700 , fontSize:"18px"}}>Business Application and Digital ProcessBuild applications and business-critical procedures to swiftly leverage 5G potential.</Typography>
                                    <Typography style={{  fontSize: "18px" }}>Businesses and organisations are increasingly seeking for solutions to reduce the time and costs associated with complex application project methodologies, reduce go-to-market limitations, boost agility, and quickly capitalise on the 5G / IoT growth potential.</Typography>
                               
            </div>
                            </Button>
                        </Grid>
                    </Grid>
                    <Box style={styles.rootbox30} className="rootbox14">
                        <Box style={{ display: "flex"}}  >
                            <Button data-testId="FreeTrialButton1" onClick={() => this.handleSignUpRedirect()} style={{ backgroundColor: "#1391AB", textTransform: "inherit", color: "white", height: "56px", width: "33vw", fontWeight: 700, marginTop:"10px",}}  >Claim Your FREE TRIAL</Button>
                        </Box>
                    </Box> 
                </Box>
                <Box style={styles.rootbox31}>
                    <Box style={styles.rootbox32 }>
                        <img src={image07} />
                    </Box>
                    <Typography style={{ textAlign: "center", marginTop: "2%", fontWeight: 700, fontSize:"32px", lineHeight:"48px"}}>
                        100% Rock-Solid
                    </Typography>
                    <Typography style={{ textAlign: "center", fontWeight: 700 , fontSize:"32px", lineHeight:"48px"}}>
                        Value and Success Guarantee!</Typography>
                    <Typography style={{ textAlign: "center", marginTop: "2%" ,fontWeight: 400 , fontSize:"24px", lineHeight:"36px" }}>
                        When you join us at ENGEEM, you can invest with confidence because
                    </Typography>
                    <Typography style={{ textAlign: "center",fontWeight: 400 , fontSize:"24px", lineHeight:"36px"}}>
                        you’ll always be covered by our Pay-After-Value-Delivery guarantee!
                    </Typography>
                    <Typography style={{ textAlign: "center", marginTop: "2%" , fontWeight: 400 , fontSize:"24px", lineHeight:"36px" }}>
                        In the extremely unlikely event you’re not satisfied with our product deliverables or we can’t help</Typography>
                    <Typography style={{ textAlign: "center" , fontWeight: 400 , fontSize:"24px", lineHeight:"36px" }}> you automate a specific part of your network operation after 60 days period, we will higher our </Typography>
                    <Typography style={{ textAlign: "center", fontWeight: 400 , fontSize:"24px", lineHeight:"36px"  }}>service level for exclusive customisation and personal babysat service free of charge until you </Typography>
                    <Typography style={{ textAlign: "center", marginBottom:"5%" , fontWeight: 400 , fontSize:"24px", lineHeight:"36px" }}>reach the objectives you’ve invested with us – No questions asked.</Typography>
                </Box>
                <Box style={styles.rootbox34}>
                    <Box style={{ padding: "5%",marginBottom:"12rem" }}>
                        <Typography style={{ textAlign: "center", fontWeight: 700, fontSize:"32px", lineHeight:"37px"}}>
                            Here is what you will be delivered with:
                        </Typography>
                        <Typography style={{ textAlign: "center", marginTop: "1%", fontWeight:700,fontSize:"24px", lineHeight:"36px" }}>
                            Automate Your Network Monitoring in less than 3 months Without Painful </Typography>
                        <Typography style={{ textAlign: "center", fontWeight:700,fontSize:"24px", lineHeight:"36px" }}> Cost and Tools, While Saving you 200 K€ per Year on your IT/NetOps Plan.
                        </Typography>
                        <Typography style={{ textAlign: "center", color: "#1391AB", fontWeight:700,fontSize:"32px", lineHeight:"37px"}}>
                            ENGEEM Offer Total Value : 205 000 Eur
                        </Typography>
                        <Grid container spacing={2} style={{ padding: "1%", marginTop:"5%"}} >
                            <Grid item xs={4}>
                                <Button style={{ display: "block", background: "white", borderRadius: "7%", height: "300px", textTransform: "inherit", textAlign: "start" }}>
                                    <Box style={{border:"1 px solic red"}}>
                                    <img src={imageRound1} style={{ height: "20%", width: "8%" }}  />

                                    </Box>
                                    <Box>
                                    <Typography style={{ fontWeight: 700, fontSize: "18px", marginBottom: "17%" }}>3 Months Perfect Network Monitoring Strategy Blueprint ( 32 K€ Value ).</Typography>

                                    </Box>
                                   
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button style={{ display: "block", background: "white", borderRadius: "7%", height: "300px", textTransform: "inherit", textAlign: "start" }}>
                                    <img src={imageRound2} style={{ height: "20%", width: "8%" , marginTop:"-3px"}} />
                                    <Typography style={{ fontWeight: 700, fontSize: "18px", marginBottom: "16%"}}>ENGEEM - PLATFORM: 6 - Months Enterprise Account license ( 38 K€ Value ).</Typography>

                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button style={{ display: "block", background: "white", borderRadius: "7%", height: "300px", textTransform: "inherit", textAlign: "start" }}>
                                    <img src={imageRound3} style={{ height: "20%", width: "8%" }} />
                                    <Typography style={{ fontWeight: 700, fontSize: "18px", marginBottom: "10%" }}> Instant Network Problem Hack with Integrated Anomaly Pattern and Predictive User Experience ( 50 K€ Value ).</Typography>

                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button style={{ display: "block", background: "white", borderRadius: "7%", height: "300px", textTransform: "inherit", textAlign: "start" }}>
                                    <img src={imageRound4} style={{ height: "20%", width: "8%"}} />
                                    <Typography style={{ fontWeight: 700, fontSize: "18px", marginBottom:"15px" }}> Automated Performance Routine Step-by-Step Automation Guideline and Templates ( 25 K€ Value ).</Typography>

                                </Button>
                            </Grid>
                            <Grid item xs={4} style={{ display: "flex", justifyContent: "center" }}>
                                <Button style={{ display: "block", background: "white", borderRadius: "7%", height: "300px", textTransform: "inherit", textAlign: "start" }}>
                                    <img src={imageRound5} style={{ height: "20%", width: "8%", marginTop:"-11px"  }} />
                                    <Typography style={{ fontWeight: 700, fontSize: "18px"}}>Fully Extensible and Customisable Big Data Analytics Base to go beyond network monitoring ( 42 K€ Value ).</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={4} style={{ display: "flex", justifyContent: "center" }}>
                                <Button style={{ display: "block", background: "white", borderRadius: "7%", height: "301px", textTransform: "inherit", textAlign: "start" }}>
                                    <img src={imageRound6} style={{ height: "20%", width: "8%" , marginTop:"-3px"}} />
                                    <Typography style={{ fontWeight: 701, fontSize: "18px", marginBottom:"20px" }}>Unlimited Metrics and Pre-Integrated Operational Analytics ( 18 K€ Value ).</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                
                <Box style={{ backgroundImage: `url(${Frame62})`, backgroundSize: '136%', backgroundRepeat: 'no-repeat', backgroundPosition: "center", width:"90%", margin:"auto" , height:"100%",}}  >
                    <Typography style={{ textAlign: "center", marginTop: "3%", fontWeight:700, fontSize:"32px", lineHeight:"37px"}} className="rootbox17" >
                        How much you pay now ?
                    </Typography>
                    <Typography style={{ textAlign: "center", color: "#1391AB", fontWeight: 700, marginTop: "1%", fontSize:"32px", lineHeight:"48px" }}>
                        Get Operational Tips and Strategies for effective
                    </Typography>
                    <Typography style={{ textAlign: "center", color: "#1391AB", fontWeight: 700, marginTop: "5px", fontSize:"32px", lineHeight:"48px" }}>
                        Management System for only 25 000 Eur.
                    </Typography>
                    <Typography style={{ textAlign: "center", marginTop: "2%", fontWeight:700, marginBottom:"40px", fontSize:"24px", lineHeight:"28px"}}>
                        The price include the :
                    </Typography>
                    <Grid container spacing={2} style={{ padding: "3%" ,height:"79%", }} >
                        <Grid  style={{marginLeft:"inherit"}} item xs={4}>
                            <Button style={{ display: "block", background: "linear-gradient(144.7deg, rgba(19, 145, 171, 0.5) 7.25%, #EDF9FC 100.6%)", borderRadius: "7%", width: "100%", height: "206px", textTransform: "inherit"}}>
                                <Box style={{ marginBottom: "20%"}}>
                                    <img src={card} style={{ height: "20%", width: "20%", marginLeft: "5%" }} />
                                    <Typography style={{ fontWeight: 700, marginLeft: "-7px", marginTop: "5%",fontSize:'20px' }}  >Zero Software fees</Typography>
                                </Box>
                            </Button>
                        </Grid>
                        <Grid style={{marginLeft:"inherit"}} item xs={4}>
                            <Button style={{ display: "block", background: "linear-gradient(144.7deg, rgba(19, 145, 171, 0.5) 7.25%, #EDF9FC 100.6%)", borderRadius: "7%", height: "206px", textTransform: "inherit", }}>
                                <Box style={{ marginBottom: "18%" }}>
                                    <img src={service} style={{ height: "20%", width: "20%", marginLeft: "5%" }} />
                                    <Typography style={{ fontWeight: 700, marginLeft: "-14px", marginTop: "5%",letterSpacing:"0.11938em",fontSize:'20px'}} className="rootbox16">Dedicated expert team for build and service adjustment.</Typography>
                                </Box>
                            </Button>
                        </Grid>
                        <Grid style={{marginLeft:"auto"}} item xs={4}>
                            <Button style={{ display: "block", background: "linear-gradient(144.7deg, rgba(19, 145, 171, 0.5) 7.25%, #EDF9FC 100.6%)", borderRadius: "7%", height: "206px", textTransform: "inherit" ,margin:"-10px",marginTop:'0px'}}>
                                <Box style={{ marginBottom: "7%" }}>
                                    <img src={computer} style={{ height: "20%", width: "20%", marginLeft: "5%" }} />
                                    <Typography style={{ fontWeight: 700,  marginTop: "-2%",fontSize:'19px' }} >100% project resources access with strategy blueprints, discoveries, and intelligence for effective network management transformation.</Typography>
                                </Box>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <Box style={styles.rootboxsignup}>
                    <Typography style={{ textAlign: "center", fontWeight: 700, color: "#1391AB", fontSize: "36px" , marginTop:"8%" , lineHeight:"42px"}}>
                        Sign up today for the Quickest Start!
                    </Typography>
                    <Typography style={{ textAlign: "center", fontWeight: 700, fontSize: "24px", marginTop: "1%" ,lineHeight:"36px"}}>
                        Get Started now and we'll put you in our next group, which begins on @Monday; otherwise,
                    </Typography>
                    <Typography style={{ textAlign: "center", fontWeight: 700, fontSize: "24px", lineHeight:"36px" }}>
                        you'll have to wait until our next kickoff date.
                    </Typography>
                    <Box style={{ display: "flex", justifyContent: "center", marginTop: "1%" }}>
                        <Box style={{ width: "72%" }}>
                            <Typography style={{ textAlign: "center", fontWeight:400, fontSize: "24px" , letterSpacing:"0.09938em", lineHeight:"36px"}}>
                                Because we are so meticulous about the quality of our deliverables and the client delivery technique, we are limited to handling 20 new projects this month to ensure the greatest possible one-on-one success! If we are sold out, please leave your email address in order to be notified of any updates or future open sessions! You will be added to our elite waiting list ...The next session begins on October 10th.
                            </Typography>
                        </Box>
                    </Box>
                    <Typography style={{ textAlign: "center", marginTop: "2%" , fontWeight:400, fontSize: "24px" , lineHeight:"28px"}}>
                        + Project Build Capacity countdown in %ENGEEM Offer Total Value : 205 000
                    </Typography>
                    <Box style={{ display: "flex", marginTop: "32px", marginBottom: "5%", justifyContent: "center" }}>
                        <Box style={{ display: "flex" }}>
                            <Button data-testId="loginpage1"  onClick={() => this.handleloginRedirect()} style={{ backgroundColor: "#1391AB", color: "white", width: "30vw", fontWeight: 700 }}>LOGIN NOW AND GET YOUR PROJECT KICKED-OFF</Button>
                        </Box>
                    </Box>
                </Box>
                <Box style={{ backgroundImage: `url(${Frame66})`, backgroundSize: 'cover', backgroundPosition: 'center',marginTop:"5rem", backgroundRepeat: 'no-repeat', width:"100%"}}>
                    <img src={image_logo} style={{ marginLeft: "4%", width: "30%" }} />
                    <Typography style={{ fontWeight: 700, color: "white", marginLeft: "4%", fontSize: "31px" }}>
                        Contact Us
                    </Typography>
                    <Typography style={{ color: "white", marginLeft: "4%", width: "40%", fontSize: "18px" }}>
                        Your feedback matters. contact us with your suggestions, comments, or inquiries, and we’ll be in touch.
                    </Typography>
                    <Box style={{ marginTop: "32px", marginBottom: "2%", marginLeft: "2%" }}>
                        <Box>
                            <Typography style={{ color: "white", fontSize: "18px" , marginLeft: "2%"}}>Email Address : customerexperience@engeem.com</Typography>
                            <Typography style={{ color: "white", fontSize: "18px", marginLeft: "2%" }}>Phone Number : (33) 00 11 11 22 19</Typography>
                        </Box>
                        <Box>
                            <Divider style={{ background: "#F3F3F3", marginTop: "2%", width: "90%" }} />
                        </Box>
                        <Box style={{ marginTop: "2%" }}>
                            <Box style={styles.imageshocialMedia}>
                                <img style={{ height: "25px", marginTop: "5px" }} src={Shape}></img>
                                <img style={{ marginLeft: "7px", height: "25px", marginTop: "5px", width: "30px" }} src={youtube}></img>
                                <img src={Google}></img>
                                <img src={Facebook}></img>
                                <img src={Twitter}></img>
                            </Box>
                        </Box>
                    </Box>

                </Box>
            </>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
const styles = ({
    rootbox34: { backgroundImage: `url(${Frame68})`, backgroundSize: '1420px', backgroundRepeat: 'no-repeat', backgroundPosition: "center", width:"90%", margin:"auto", height:"139%"},
    rootbox32: { display: "flex", justifyContent: "center", marginTop:"2.5%"},
    rootbox31: { backgroundImage: `url(${Frame47})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width:"90%", margin:"auto"},
    rootbox30: { display: "flex", marginTop: "32px", marginBottom: "5%", justifyContent: "center" },
    rootbox28: { background: "#EDF9FC", maxWidth: "560px", borderRadius: "16px", height: "472px", padding: "24px 8px 24px 8px", gap: "12px" },
    rootbox26: { display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateRows: 'repeat(2, 1fr)', gap: '46px', padding: "80px 80px 52px 80px", height: '1270px' },
    rootbox23: { background: "linear-gradient(37.13deg, rgba(26, 194, 194, 0.7) 0%, #1391AB 100.85%)", marginTop:"60px", width:"90%", margin:"auto" },
    rootbox22: { fontWeight: 700, marginLeft: "8%", marginTop: "-3%", fontSize: "24px", lineHeight:"36px" },
    rootbox21: { height: "42%", width: "90%",marginTop:"3%" },
    rootbox20: { height: "42%", width: "90%", marginTop: "5%" },
    rootbox19: { display: "flex", marginTop: "32px", marginBottom: "5%", marginLeft: "5%" },
    rootbox18: { display: "flex", justifyContent: "space-between", padding: "1% 2% 3% 6%", height: "60%" },
    rootbox15: { backgroundImage: `url(${Frame12})`, backgroundSize: '145%', backgroundRepeat: 'no-repeat', backgroundPosition: "right", width:"90%", margin:"auto",marginBottom:"50px"},
    rootbox13: { marginTop: "0.5%", marginLeft: "3%", fontSize: "20px", lineHeight: "23px" },
    rootbox11: { width: "45%", lineHeight: "125%", marginRight: "3%" },
    rootbox10: { display: "flex", justifyContent: "center", backgroundImage: `url(${Frame22})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', padding: "6% 0% 6% 0%", height: "128%", width:"90%", margin:"auto", marginTop:"-19px", },
    rootbox9: { fontWeight: 700, lineHeight: "28px", fontSize: "24px", marginTop: "2%", fontFamily: "Roboto" },
    rootbox8: { fontWeight: 400, lineHeight: "36px", fontSize: "24px", marginTop: "2%", fontFamily: "Roboto" },
    rootbox7: { fontWeight: 400, lineHeight: "36px", fontSize: "24px", marginTop: "2%", fontFamily: "Roboto" },
    rootbox5: { height: "110%", width: "90%", backgroundImage: `url(${Frame11})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', margin:"auto"},
    rootbox4: { fontWeight: 400, lineHeight: "36px", fontSize: "24px", width: "47%", fontFamily: "Roboto",letterSpacing:"0.02938"},
    rootbox3: { fontWeight: 700, fontSize: "36px", fontFamily: "Roboto", marginTop: "2%", width: "80%", color: "#232638", lineHeight:"60px"},
    rootboxsignup: { background: "#1AC2C233",backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: "250%", width: "90%",margin:"auto", marginBottom:"-18px"},
    rootbox1: { backgroundImage: `url(${Frame78})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: "250%", width: "90%",margin:"auto", marginBottom:"-18px"},
    imageshocialMedia: { display: "flex", gap: "20px", marginLeft:"20px" },
    buttonroot: { display: "flex", marginTop: "-69px", marginBottom: "5%" , marginLeft:"-32px"},
    mainBox: { background: "white", padding: "32px 24px 32px 24px", display: "flex", justifyContent: "space-between"},
  

   
  
}

)

