import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  gettoken1: string;
  accountId: string;
  password: string;
  token: string;
  confirmPassword: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  TermsAndConditionsAccept: boolean;
  TermsAndConditionsDec: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  buttonChange: string;
  passwordStrength: string;
  open: boolean;
  confirmButtom: boolean;
  popupModal: boolean;
  recoveryButton: boolean;
  isLengthValid: boolean;
  isUpperCaseValid: boolean;
  isLowerCaseValid: boolean;
  isNumberValid: boolean;
  error: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  createAccountApiCallId: string = "";
  forgotPasswordApiCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      gettoken1: "",
      accountId: "",
      email: "",
      error: null,
      token: "",
      password: "",
      confirmPassword: "",
      enablePasswordField: false,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      buttonChange: "SignUp",
      open: false,
      confirmButtom: false,
      popupModal: false,
      recoveryButton: false,
      isLengthValid: false,
      isUpperCaseValid: false,
      isLowerCaseValid: false,
      isNumberValid: false,
      passwordStrength: "",
      TermsAndConditionsAccept: true,
      TermsAndConditionsDec: false
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const datavalue = window.location.pathname
    if (datavalue === "/EmailAccountLoginBlock") {
      this.setState({ buttonChange: "Login" })
    } else if (datavalue === "/SignUpPage/false") {
      this.setState({ buttonChange: "SignUp" })
    }

    // Customizable Area End
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setConfirmPassword = (text: string) => {
    this.setState({
      isLengthValid: this.state.confirmPassword.length >= 8,
      isUpperCaseValid: /[A-Z]/.test(this.state.confirmPassword),
      isLowerCaseValid: /[a-z]/.test(this.state.confirmPassword),
      isNumberValid: /\d/.test(this.state.confirmPassword),
    });
    if (
      this.state.isLengthValid &&
      this.state.isUpperCaseValid &&
      this.state.isLowerCaseValid &&
      this.state.isNumberValid
    ) {
      this.setState({ passwordStrength: configJSON.strongPassword })

    } else {
      this.setState({ passwordStrength: configJSON.weakPassword });
    }
  }

  setPassword = (text: string) => {
    this.setState({
      password: text,
      confirmPassword: text,
      isLengthValid: this.state.password.length >= 8,
      isUpperCaseValid: /[A-Z]/.test(this.state.password),
      isLowerCaseValid: /[a-z]/.test(this.state.password),
      isNumberValid: /\d/.test(this.state.password),
    });

    // Check overall password strength
    if (
      this.state.isLengthValid &&
      this.state.isUpperCaseValid &&
      this.state.isLowerCaseValid &&
      this.state.isNumberValid
    ) {
      this.setState({ passwordStrength: configJSON.strongPassword })

    } else {
      this.setState({ passwordStrength: configJSON.weakPassword });
    }
  };
  handleNavigate = () => {
    localStorage.setItem('email', JSON.stringify({ email: this.state.email }));
    this.props?.navigation?.navigate("TermsAndConditions");
  }
  handleAccept = () => {
    this.setState({ TermsAndConditionsAccept: true })
    const userEmail = JSON.parse(localStorage.getItem('email')!)?.email;
    localStorage.setItem('checkedRememberMe', JSON.stringify(this.state.TermsAndConditionsAccept));
    this.setState({ email: userEmail });
    this.props?.navigation?.navigate("SignUpPage", {
      orgTab: "false",
    });
  }


  handlSignupPage = () => {
    // this.setState({ buttonChange: "SignUp" });
    localStorage.removeItem('checkedRememberMe')

    this.props?.navigation?.navigate("SignUpPage", {
      orgTab: "false",
    })

  }
  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  handleLoginPage = () => {
    this.props?.navigation?.navigate("EmailAccountLoginBlock")
  }
  handleOpen = () => {
    this.props?.navigation?.navigate("SignUpPage", {
      orgTab: "false",
    })
  };
  handleClose = () => {
    this.setState({ open: false })
  };


  handleConfirmEmail = () => {
    const { email, checkedRememberMe } = this.state;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (!email || !emailPattern.test(email)) {
      this.setState({ error: 'Invalid email address' });
      this.setState({ checkedRememberMe: false });
      this.setState({ confirmButtom: false });
    } else if (!checkedRememberMe) {
      this.setState({ error: 'You have to agree with Privacy Policy and Terms & Conditions to Sign up' });
      this.setState({ confirmButtom: false });
    } else {
      this.setState({ error: null });
      this.setState({ confirmButtom: true });
      localStorage.removeItem('email');
      localStorage.removeItem('checkedRememberMe')
      this.handleOpenConfirmPassword();
    }

  }

  handleEmailChange = (e: any) => {
    this.setState({ email: e.target.value });
  };

  handleModal = () => {
    if (this.state.confirmButtom) {
      this.setState({ popupModal: true })
      this.setState({ open: false })
      this.confirmemail()

    }
    this.confirmemail()

  }
  handleForgotPasswordLogin = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.gettoken1
    };

    const data = {
      token: this.state.gettoken1,
      password: this.state.password,
      password_confirmation: this.state.confirmPassword
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_forgot_password/passwords'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleResetEmail = () => {
    const header = {
      "Content-Type": "application/json",
    };

    const data = {
      token: this.state.gettoken1,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_forgot_password/otp_confirmations'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleRecovery = () => {
    const header = {
      "Content-Type": "application/json",
    };
    const attr = {
      email: this.state.email
    }
    const data = {
      type: "otp",
      attributes: attr,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.forgotPasswordApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_forgot_password/otps'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ open: true, recoveryButton: true })
  }
  confirmemail = () => {
    const header = {
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account/accounts/email_confirmation'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ confirmButtom: true })
  }

  handleOpenConfirmPassword = () => {
    const header = {
      "Content-Type": "application/json",
    };

    const data = {
      type: "email_account",
      email: this.state.email,
    };

    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts'
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ open: true })
  };

  handleSignUp = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    };

    const data = {
      password: this.state.password,
      password_confirmation: this.state.confirmPassword,
    };

    const httpBody = {
      account_id: this.state.accountId,
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/set_password'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  doLogIn = () => {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const httpBody = {
      type: "email_account",
      email: this.state.email,
      password: this.state.password,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_login/logins'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.props?.navigation?.navigate("/LandingPageWeb")
  }

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiEmailLoginCallId || responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        this.saveLoggedInUserData(responseJson);
        this.sendLoginSuccessMessage();
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
        this.sendLoginFailMessage();
      }
      if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.createAccountApiCallId != null &&
        this.createAccountApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) || responseJson && responseJson.meta && responseJson.meta.token
      ) {
        this.setState({ token: responseJson.meta.token, accountId: responseJson.data.id })
        this.saveLoggedInUserData(responseJson);
      } else if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      }
      else if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.forgotPasswordApiCallId != null &&
        this.forgotPasswordApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.setState({ gettoken1: responseJson.meta.token })
      }
    }


    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
