import React from "react";

// Customizable Area Start
import {
	Box,
	Button,
	Input,
	Typography,
	Checkbox,
	InputLabel,
	Modal,
	InputAdornment,
	IconButton,
	TextField,
	// Customizable Area Start
	// Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CommonComponent from "./CommonComponent.web";
import { Link } from "react-router-dom";
import { Wrong, ValidIcon, InValidIcon } from "./assets";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area End

import EmailAccountLoginController, {
	Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
	palette: {
		primary: {
			main: "#0000ff",
			contrastText: "#fff",
		},
	},
});
// Customizable Area End


export default class SignUpPage extends EmailAccountLoginController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}
	// Customizable Area Start
	async componentDidMount(): Promise<void> {
		const rememberMe = JSON.parse(localStorage?.getItem('checkedRememberMe')!);	  
		if (rememberMe) {
		  this.setState({ checkedRememberMe: rememberMe });
		}
	  
		const emailString = localStorage.getItem('email');
	  
		if (emailString) {
		  try {
			const userEmail = JSON.parse(emailString)?.email;
	  
			if (userEmail) {
			  this.setState({ email: userEmail });
			}
		  } catch (error) {
			console.error('Error parsing email from localStorage:', error);
		  }
		}
	  }
	// Customizable Area End
	render() {

		return (
			// Customizable Area Start
			// Required for all blocks
			<>
				{
					this.state.confirmButtom && (

						<Modal
							className="modalThanks"
							data-testId="modalSignup1"
							open={this.state.open}
							onClose={() => this.handleClose()} >

							<Box className="webStyle">
								<Box className="thanks-box">
									<img data-testId="handleClose" onClick={() => this.handleClose()} src={Wrong} />
								</Box>
								<Typography className="webStyle1">Thank you</Typography>
								<Typography style={{ marginTop: "5%" }}>Please check your inbox for a confirmation email.</Typography>
								<Typography> Follow the link provided to complete your Registration.</Typography>
								<Box className="buttonroot1">
									<Box className="Checkinbox" style={{ }}>
										<Button data-testId="Checkinbox" onClick={() => { this.handleModal() }}>Check inbox</Button>
									</Box>
								</Box>
							</Box>
						</Modal>
					)
				}
				<ThemeProvider theme={theme}>
					<Box className="mainRoot">
						<CommonComponent navigation={undefined} id={""} />
						<Box className="rootbox">
							<Box className="boxRoot">
								<Box className="welcomeBox">
									Welcome !
								</Box>
								<Box data-testId="handlSignupPage"
									className="boxRoot6">
									<Box className="boxRoot7">
										<Box 
											className="boxRoot9">
											<Box className="signup-box"
											 data-testId="handlSignupPage2"
											 style={{display: 'flex',
												alignItems: 'center',
												paddingRight: '20px',
												background: this.state.buttonChange === "SignUp" ? "#1391AB" : "white",
											}} onClick={() => this.handlSignupPage()}>
												<Typography style={{fontWeight: 700}} className="signup-box-text">Sign up </Typography>
											</Box>
											<Box className="handleLoginPage" data-testId="handleLoginPage"
												style={{
													 background: this.state.buttonChange === "Login" ? "#1391AB" : "white",
												}}
												onClick={() => this.handleLoginPage()}><Typography  style={{fontWeight: 700}} className="signup-box-text">Login</Typography></Box>
										</Box>
									</Box>
								</Box>
								{this.state.error && (
									<div className="error-popup" style={{ color: '#8D0707', backgroundColor: '#FEE2E2', borderLeft: 'solid 4px #DC2626', padding: '7px 10px 7px 12px' }}>
										{this.state.error}
									</div>
								)}
								{this.state.confirmButtom === false && (
									<>
										<Box className="emailPAswordRoot">
											<InputLabel className="lableStyle">Email address</InputLabel>
										</Box>
										<Box className="inputemail">
											<Input placeholder=" Enter your email" data-testId="setEmail" value={this.state.email} onChange={this.handleEmailChange} className="emailinput" type="text" />
										</Box>
										<Box className="checkbox checkBoxRoot">
											<Checkbox data-testId="btnRememberMe"
												onClick={() =>
													this.setRememberMe(!this.state.checkedRememberMe)
												}
												checked={(this.props.navigation?.getParam('orgTab') === "true") ? (this.props.navigation?.getParam('orgTab') === "true") : this.state.checkedRememberMe} />

											<Typography  className="typographyRoot">I agree with <span data-testId="handleNavigate" onClick={() => this.handleNavigate()} style={{ color: "black" }}><span style={{textDecoration:"underline"}}>Terms and Conditions </span>and <span style={{textDecoration:"underline"}}>privcay Policy </span></span> </Typography>

										</Box>


										<Box className="buttonroot">
											<Box style={{ display: "flex" }}>
												<Button data-testId="handleOpenConfirmPassword" onClick={() => {

													this.handleConfirmEmail();

												}} >Confirm email address</Button>
											</Box>
										</Box>
										<Box className="resend-box">
											<Typography data-testId="handleOpen" onClick={() => this.handleOpen()} className="signupAccountStyle1">Resend email </Typography>
											<Typography className="signupAccountStyle">Have an account? <Box style={{ color: "white" }}>/</Box><Link className="loginlink" to="/EmailAccountLoginBlock">Login</Link></Typography>
										</Box>
									</>
								)}

								{/* <Modal
												open={this.state.open}
												onClose={() => this.handleClose()}>
												<Box sx={styles.webStyle}>
													<Box sx={{ display: "flex", justifyContent: "end" }}>
														<img onClick={() => this.handleClose()} src={Wrong} />
													</Box>
													<Typography style={styles.webStyle1}>Thank you</Typography>
													<Typography style={{ marginTop: "5%" }}>You have successfully created yourAccount.</Typography>
													<Box style={styles.buttonroot1}>
														<Box style={{ display: "flex", width: "100%" }}>
															<Button style={{ background: "#1391AB", borderRadius: "8px", width: "100%", textTransform: "inherit", color: "white" }} onClick={() => this.handleClose()}>Done</Button>
														</Box>
													</Box>
												</Box>
											</Modal> */}

								{
									this.state.popupModal === true && (
										<>
											<Box className="inputlablepassword">
												<InputLabel className="lableStyle">Password
												</InputLabel>
											</Box>
											<Box className="emailPAswordRoot">
												<TextField data-testId="TextFieldhandleClickShowPassword"
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	data-testid="handleClickShowPassword"
																	style={{ marginRight: "2px" }}
																	aria-label="toggle password visibility"
																	onClick={() => this.handleClickShowPassword()}
																	edge="end"
																>
																	{this.state.enablePasswordField ? (
																		<Visibility
																			data-testid="enablePasswordField" style={{ color: "#94A3B8" }} />
																	) : (
																		<VisibilityOff style={{ color: "#94A3B8" }} />
																	)}
																</IconButton>
															</InputAdornment>
														),
													}}
													placeholder=" Enter your password" value={this.state.password}
													onChange={(e: any) => this.setPassword(e.target.value)}
													className="psw-input"
													type={this.state.enablePasswordField ? "text" : "password"} >

												</TextField>
											</Box>
											<Box className="inputlablepassword">
												<InputLabel className="lableStyle">Confirm Password
												</InputLabel>
											</Box>
											<Box data-testId="textinputtest" className="emailPAswordRoot">
												<TextField
													data-testId="setConfirmPassInput"
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	style={{ marginRight: "2px" }}
																	aria-label="toggle password visibility"
																	edge="end"
																>
																	{this.state.enablePasswordField ? (
																		<Visibility style={{ color: "#94A3B8" }} />
																	) : (
																		<VisibilityOff style={{ color: "#94A3B8" }} />
																	)}
																</IconButton>
															</InputAdornment>
														),
													}} placeholder=" Confirm your password"
													value={this.state.confirmPassword} onChange={(e: any) => this.setConfirmPassword(e.target.value)}
													type={this.state.enablePasswordField ? "text" : "password"}
													className="psw-input">
												</TextField>
											</Box>
											<Box>
												<ul style={{ listStyle: "none" }}>
													<li>{this.state.isUpperCaseValid ? <img src={ValidIcon} /> : <img src={InValidIcon} />} At least one capital letter </li>
													<li>{this.state.isLowerCaseValid ? <img src={ValidIcon} /> : <img src={InValidIcon} />} At least one lowercase letter</li>
													<li>{this.state.isNumberValid ? <img src={ValidIcon} /> : <img src={InValidIcon} />} At least one number</li>
													<li>{this.state.isLengthValid ? <img src={ValidIcon} /> : <img src={InValidIcon} />} Minimum character length is 8 characters</li>
												</ul>
											</Box>
											<Box className="buttonroot">
												<Box style={{ display: "flex" }}>
													<Button data-testId="handleSignUp" className="signupBtn" onClick={() => { this.handleSignUp() }}>Sign up</Button>
												</Box>
											</Box>
											<Box>
												<Typography className="signupAccountStyle">Have an account? <Link className="loginBtn" to="/EmailAccountLoginBlock">Login</Link></Typography>
											</Box>
										</>
									)

								}
							</Box>
						</Box>
					</Box>
				</ThemeProvider>
			</>
			// Customizable Area End
		);
	}

}
// Customizable Area Start
// Customizable Area End
