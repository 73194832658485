// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import DragDropInterface from "../../blocks/dragdropinterface/src/DragDropInterface";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Widgets2 from "../../blocks/widgets2/src/Widgets2";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import FormApprovalWorkflow from "../../blocks/formapprovalworkflow/src/FormApprovalWorkflow";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Cfcustomisableworkflowbyusers from "../../blocks/cfcustomisableworkflowbyusers/src/Cfcustomisableworkflowbyusers";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Cfadditionalchartoptions from "../../blocks/cfadditionalchartoptions/src/Cfadditionalchartoptions";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Cfalertcreation from "../../blocks/cfalertcreation/src/Cfalertcreation";
import CfalertcreationWeb from "../../blocks/cfalertcreation/src/Cfalertcreation.web";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SignUpPage from "../../blocks/email-account-login/src/SignUpPage.web";
import ForgotPasswordLoginBlock from "../../blocks/email-account-login/src/ForgotPasswordLoginBlock.web";
import TermsAndConditions from "../../blocks/email-account-login/src/TermsAndConditions.web";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Cfmetriccreationtoolforusers from "../../blocks/cfmetriccreationtoolforusers/src/Cfmetriccreationtoolforusers";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import LandingPageWeb from "../../blocks/landingpage/src/LandingPage.web";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";



const routeMap = {
  DragDropInterface: {
    component: DragDropInterface,
    path: "/DragDropInterface"
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  Widgets2: {
    component: Widgets2,
    path: "/Widgets2"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  FormApprovalWorkflow: {
    component: FormApprovalWorkflow,
    path: "/FormApprovalWorkflow"
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: "/ApiIntegration"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  Scheduling: {
    component: Scheduling,
    path: "/Scheduling"
  },
  Cfcustomisableworkflowbyusers: {
    component: Cfcustomisableworkflowbyusers,
    path: "/Cfcustomisableworkflowbyusers"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Cfadditionalchartoptions: {
    component: Cfadditionalchartoptions,
    path: "/Cfadditionalchartoptions"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  Cfalertcreation: {
    component: Cfalertcreation,
    path: "/Cfalertcreation"
  },
  CfalertcreationWeb: {
    component: CfalertcreationWeb,
    path: "/CfalertcreationWeb"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  LandingPageWeb: {
    component: LandingPageWeb,
    path: "/LandingPageWeb"
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: "/TermsAndConditions"
  },
  ForgotPasswordLoginBlock: {
    component: ForgotPasswordLoginBlock,
    path: "/ForgotPasswordLoginBlock"
  },
  SignUpPage: {
    component: SignUpPage,
    path: "/SignUpPage/:orgTab"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },

  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Cfmetriccreationtoolforusers: {
    component: Cfmetriccreationtoolforusers,
    path: "/Cfmetriccreationtoolforusers"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  LandingPageWeb: {
    component: LandingPageWeb,
    path: "/LandingPageWeb"
  },

  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },

  Home: {
    component: Scheduling,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
