import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    TextField,
    Input,
    Typography,
    InputAdornment,
    IconButton,
    InputLabel,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";
import { Link } from "react-router-dom";
// Customizable Area Start

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

// Customizable Area End

import EmailAccountLoginController, {
    Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End


export default class LoginPage extends EmailAccountLoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            // Required for all blocks
            <ThemeProvider theme={theme}>
                <Box style={{ display: "flex", marginTop: "15px" }}>
                    <InputLabel className="lableStyle">Email address</InputLabel>
                </Box>
                <Box className="inputemail">
                    <Input data-testId="txtInputEmail" placeholder=" Enter your email" value={this.state.email} onChange={(e: any) => this.setEmail(e.target.value)} style={{ border: '1px solid #70b3b7', borderRadius: "4px", width: "99%", background: "white" }} type="text" />
                </Box>
                <Box className="inputlablepassword">
                    <InputLabel className="lableStyle">Password
                    </InputLabel>
                </Box>
                <Box className="inputemail">
                    <TextField
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        style={{ marginRight: "2px" }}
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickShowPassword}
                                        edge="end"
                                    >
                                        {this.state.enablePasswordField ? (
                                            <Visibility style={{ color: "#94A3B8" }} />
                                        ) : (
                                            <VisibilityOff style={{ color: "#94A3B8" }} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }} placeholder=" Enter your password" data-testId="txtInputPassword" value={this.state.password}
                        type={this.state.enablePasswordField ? "text" : "password"}
                        onChange={(e: any) => this.setPassword(e.target.value)}
                        className="inputFilds"
                        style={{  border: '1px solid #70b3b7', borderRadius: "4px", width: "99%", background: "white" }}>

                    </TextField>
                </Box>
                <Link data-testId="btnForgotPassword" to="/ForgotPasswordLoginBlock" className="forgotpasswordroot">
                    Forgot Password?
                </Link>
                <Box className="buttonroot">
                    <Box style={{ display: "flex" }}>
                        <Button data-testId="btnSocialLogin" onClick={() => this.doLogIn()} style={{ backgroundColor: "#1391AB", textTransform: "inherit", color: "white", width: "24.5vw" }}>Login</Button>
                    </Box>
                </Box>
                <Typography className="signupAccountStyle">Don’t have an account? <Link style={{ color: "#1391AB", marginLeft: "2%", textDecoration: "none", fontWeight: 700 }} to="/SignUpPage/false">SignUp</Link></Typography>

            </ThemeProvider>
            // Customizable Area End
        );
    }

}
// Customizable Area Start
// Customizable Area End
