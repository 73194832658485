import React from "react";
import {
    Box,
    Typography,
    Button,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";

// Customizable Area End

import EmailAccountLoginController, {
    Props,
} from "./EmailAccountLoginController";
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End


export default class EmailAccountLoginBlock extends EmailAccountLoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
            return (
            // Customizable Area Start
            // Required for all blocks
            <>
                <Box style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", background: "#e8ebeb" }}>
                    <Box>
                        <Box style={styles.mainBox}>
                            <Box>
                                <Box style={{ color: "white", fontSize: "36px" }}>
                                    Terms & Conditions
                                </Box>
                                <Box style={{ fontSize: "20px", color: "white", }}>
                                    Last updated 3 days ago
                                </Box>
                            </Box>
                            <Box>
                                <Box style={styles.boxRoot6}>
                                    <Box style={styles.boxRoot7}>
                                        <Box style={styles.boxRoot9}>
                                            <Box style={{
                                                borderRadius: "50px",
                                                color: this.state.buttonChange === "SignUp" ? "white" : "black",
                                                background: this.state.buttonChange === "SignUp" ? "#1391AB" : "white",
                                                display: "flex",
                                                height: "57px",
                                                gap: "6px",
                                                width: "142px",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontSize: "24px",

                                            }} data-testId="handlSignupPage" onClick={() => this.handlSignupPage()}>
                                                <Typography style={{ fontFamily: "Roboto Condensed" }}>Sign up</Typography>
                                            </Box>

                                            <Box style={{
                                                height: "57px",
                                                display: "flex",
                                                gap: "6px",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                fontFamily: "Roboto Condensed",
                                                color: this.state.buttonChange === "Login" ? "white" : "black",
                                                width: "142px", fontSize: "24px", background: this.state.buttonChange === "Login" ? "#1391AB" : "white", borderRadius: "50px"
                                            }}
                                                data-testId="handleLoginPage"
                                                onClick={() => this.handleLoginPage()}><Typography style={{ fontFamily: "Roboto Condensed" }}>Login</Typography></Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="scrollbar-container">
                        <Box style={{ display: "flex", padding: "2%", marginBottom: "5%" }}>
                            <Box className="content" style={{ width: "30%" }}>
                                <ol>
                                    <li style={{ color: "#1391AB" }}>Terms and Conditions</li>
                                    <li style={{ color: "#64748B", marginTop: "5%" }}>Privacy Policy</li>
                                </ol>
                            </Box>
                            <Box style={{ width: "70%" }}>
                                <Box>
                                    Lorem ipsum dolor sit amet consectetur. Donec quis posuere tincidunt amet.
                                    Sed quam accumsan rhoncus auctor vitae habitasse volutpat metus lorem.
                                    Dictum at odio aliquam a urna gravida. Feugiat arcu nunc feugiat tincidunt venenatis viverra.
                                    Tellus placerat nam volutpat urna ipsum malesuada erat convallis.
                                    Ut non a feugiat etiam urna sodales. Risus feugiat mattis in pellentesque vitae mattis.
                                    Pellentesque varius quis vulputate turpis commodo libero placerat.
                                    Non mi magnis aliquam scelerisque. Amet tempus sapien sagittis ac.
                                    Non bibendum felis duis pellentesque sed risus lorem et adipiscing.
                                    Viverra pharetra habitant senectus ultricies maecenas mattis malesuada.
                                    Mi aliquet tristique imperdiet non mollis euismod elementum vitae.
                                    Mauris porttitor vitae eget lacus aliquam. Egestas potenti lorem commodo vitae pellentesque duis eget.
                                    Eget rhoncus ac eu sed ut diam egestas integer. Odio libero auctor egestas purus nec mi purus id.
                                    Praesent non eget arcu ullamcorper velit ut sollicitudin dignissim.
                                    Mattis lorem viverra habitant pretium. Quam viverra in eu phasellus dolor mi lacus.
                                    Curabitur consequat sed sed lacus nulla velit. Augue vitae nascetur quis sit turpis non justo. Tincidunt luctus amet amet sed.
                                </Box>
                                <Box style={{ marginTop: "2%" }}>
                                    Lorem ipsum dolor sit amet consectetur. Donec quis posuere tincidunt amet.
                                    Sed quam accumsan rhoncus auctor vitae habitasse volutpat metus lorem. Dictum at odio aliquam a urna gravida.
                                    Feugiat arcu nunc feugiat tincidunt venenatis viverra.
                                    Tellus placerat nam volutpat urna ipsum malesuada erat convallis.
                                </Box>
                                <Box style={{ marginTop: "2%" }}>
                                    Lorem ipsum dolor sit amet consectetur. Donec quis posuere tincidunt amet.
                                    Sed quam accumsan rhoncus auctor vitae habitasse volutpat metus lorem. Dictum at odio aliquam a urna gravida.
                                    Feugiat arcu nunc feugiat tincidunt venenatis viverra.
                                    Tellus placerat nam volutpat urna ipsum malesuada erat convallis.
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box style={{ display: "flex", justifyContent: "end", height: "12%", background: "white" }}>
                        <Box style={{ display: "flex", gap: "5%", marginRight: "2%", marginTop: "1.5%" }}>
                            <Box>
                                <Button data-testId="handlSignupPage2" style={styles.buttonStyle1} onClick={() => {
                                    this.handlSignupPage()
                                }}>
                                    DECLINE
                                </Button>
                            </Box>
                            <Box>
                                <Button data-testId="handleAccept" style={styles.buttonStyle} onClick={() => {
                                    this.handleAccept()
                                }}>
                                    ACCEPT
                                </Button>

                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
            // Customizable Area End
        );
    }

}
// Customizable Area Start
const styles = ({
    buttonStyle1: { background: "white", color: "rgba(19, 145, 171, 1)", borderRadius: "8%" },
    buttonStyle: { background: "rgba(19, 145, 171, 1)", color: "white", borderRadius: "8%" },
    boxRoot9: { borderRadius: "50px", display: "flex", padding: "2px", background: "white", height: "56px", width: "288px" },
    boxRoot6: { display: "flex", justifyContent: "center" },
    boxRoot7: { display: "flex", width: "45%", justifyContent: "center" },
    mainBox: { background: "rgba(35, 38, 56, 1)", padding: "32px 24px 32px 24px", display: "flex", justifyContent: "space-between", }
})
// Customizable Area End
